import React from 'react'
import cookiepic from '../assets/undraw_cookie_love_re_lsjh.svg'

const Cookies = () => {
  return (
    <div className='container mx-auto p-16 my-24'>
        <img src={cookiepic} alt="Eating Cookies" />
        </div>
  )
}

export default Cookies